exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-capacity-building-js": () => import("./../../../src/pages/capacity-building.js" /* webpackChunkName: "component---src-pages-capacity-building-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contactUs.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-copy-right-js": () => import("./../../../src/pages/copy-right.js" /* webpackChunkName: "component---src-pages-copy-right-js" */),
  "component---src-pages-disclaimer-js": () => import("./../../../src/pages/disclaimer.js" /* webpackChunkName: "component---src-pages-disclaimer-js" */),
  "component---src-pages-events-js": () => import("./../../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-galleries-js": () => import("./../../../src/pages/galleries.js" /* webpackChunkName: "component---src-pages-galleries-js" */),
  "component---src-pages-google-analytics-js": () => import("./../../../src/pages/googleAnalytics.js" /* webpackChunkName: "component---src-pages-google-analytics-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-media-center-js": () => import("./../../../src/pages/mediaCenter.js" /* webpackChunkName: "component---src-pages-media-center-js" */),
  "component---src-pages-media-center-news-js": () => import("./../../../src/pages/mediaCenter/news.js" /* webpackChunkName: "component---src-pages-media-center-news-js" */),
  "component---src-pages-partner-institutes-js": () => import("./../../../src/pages/partnerInstitutes.js" /* webpackChunkName: "component---src-pages-partner-institutes-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/Search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-team-members-js": () => import("./../../../src/pages/team-members.js" /* webpackChunkName: "component---src-pages-team-members-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-useful-links-js": () => import("./../../../src/pages/useful-links.js" /* webpackChunkName: "component---src-pages-useful-links-js" */),
  "component---src-pages-website-policy-js": () => import("./../../../src/pages/website-policy.js" /* webpackChunkName: "component---src-pages-website-policy-js" */),
  "component---src-templates-basic-page-js": () => import("./../../../src/templates/basicPage.js" /* webpackChunkName: "component---src-templates-basic-page-js" */),
  "component---src-templates-gallery-js": () => import("./../../../src/templates/gallery.js" /* webpackChunkName: "component---src-templates-gallery-js" */),
  "component---src-templates-news-article-js": () => import("./../../../src/templates/newsArticle.js" /* webpackChunkName: "component---src-templates-news-article-js" */),
  "component---src-templates-resources-publications-js": () => import("./../../../src/templates/resourcesPublications.js" /* webpackChunkName: "component---src-templates-resources-publications-js" */)
}

